@import "variables", "media-queries", "mixins", "typography";
.label {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    z-index: 2;

    &.typePrimary {
        .searchIcon {
            top: 15px;
            left: 22px;
            color: $color-text-primary;
            font-size: 19px;
        }

        .clearIcon {
            top: 15px;
            right: 10px;
        }

        .input {
            padding-left: 55px;
            padding-right: 30px;
        }
    }

    &.typeSecondary {
        .searchIcon {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: $color-basic-primary;
        }

        .clearIcon {
            top: 13px;
            right: 20px;
            color: $color-basic-primary;
        }

        .input {
            padding-right: 30px;
        }
    }

    .searchIcon {
        position: absolute;
        font-size: 20px;
        z-index: 1;
    }

    .clearIcon {
        position: absolute;
        font-size: 19px;
        color: $color-status-muted;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}
