@import "variables", "media-queries", "mixins", "typography";

.wrapper {
    position: relative;
}

.inputLabel {
    position: absolute;
    top: -24px;
    left: 0;
    @include body-medium-bold;
    line-height: 1;
    color: $color-basic-secondary;
}


.input {
    display: block;
    width: 100%;
    padding: 12px 20px;
    background-color: $color-white;
    border-radius: $radius-default;
    box-shadow: $shadows-form-item !important;
    color: $color-text-primary;
    border: 1px solid transparent;
    transition: border-color 0.25s;

    &:focus {
        border-color: $color-basic-border;
        box-shadow: $shadows-form-item-focus !important;
    }

    &::placeholder {
        color: $color-status-muted;
    }

    &:disabled {
        color: $color-status-muted;
    }


    &.invalid {
        box-shadow: $shadows-form-item !important;
        border-color: $color-status-danger;
        color: $color-status-danger;
    }

    &:focus {
        + .icon {
            color: $color-basic-primary;
        }
    }

    &.invalid {
        + .icon {
            color: $color-status-danger;
        }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: none;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.inputWrap {
    position: relative;
}

.withIcon {
    .input {
        padding-left: 54px;
    }
}


.icon {
    position: absolute;
    top: 14px;
    left: 22px;
    font-size: 20px;
    color: $color-status-muted;
    z-index: 1;

}




