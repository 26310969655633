@import "variables", "media-queries", "mixins", "typography";

.default {
    margin-top: 40px;
    margin-bottom: -20px;

    &.hideMd {
        @include media-breakpoint(md) {
            display: none;
        }
    }
}

.wrapper {
    @include limit-width(570px);
    text-align: center;

    @include media-breakpoint(sm) {
        text-align: start;
        margin-left: 0;
    }
}
