@import "variables", "media-queries", "mixins", "typography";
.section {
    padding-top: 60px;
    .btn {
        margin-top: auto;
    }
}

.inner {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 100px;
    border-bottom: 1px solid $color-misc-hr;

    @include media-breakpoint(md) {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 80px;
    }

    @include media-breakpoint(sm) {
        margin-top: -35px;
        padding-bottom: 60px;
    }

    @include media-breakpoint(xs) {
        grid-template-columns: 1fr;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;

    p {
        margin-bottom: 25px;
    }
}

.cardTop {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    strong {
        @include body-big-semibold;
    }
}

.notFound {
    margin-top: 0 !important;
}
