@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;
}

.card {
    padding: 20px;
}
.cardTop {
    strong {
        display: block;
        margin: 20px 0 12px;
    }
}

.heading {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 40px;
}

.cardsWrap {
    overflow: auto;
    @include container-out;
    @include customize-scrollbars;
    padding-bottom: 20px;
    margin-bottom: -20px;
}

.cardsWrapInner {
    display: grid;
    grid-template-columns: repeat(3, 370px);
    grid-gap: 30px;

    @include media-breakpoint(sm) {
        grid-template-columns: repeat(3, 320px);
    }
}
